import {Layout} from "@components";
import * as styles from "./style.module.scss";
import {TypographyUI} from "@ui";
import {Link} from "gatsby";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {Helmet} from "react-helmet";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";

const NotFoundPage = () => (
  <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Самозанятые.рф - Страница не найдена</title>
        <meta name="description" content={`Запрашиваемая страница не существует, воспользуйтесь навигацией сайта Самозанятые.рф для перехода на другую страницу`} />
    </Helmet>
    <Layout >
        <div className={styles.container}>
            <div className={styles.text}>
                <TypographyUI.H1 swichPoint={LAPTOP_MOBILE_POINTER} className={styles.title}>Такой страницы нет на
                    сайте</TypographyUI.H1>
                <TypographyUI.Body1Regular className={styles.description}><>Пожалуйста, воспользуйтесь поиском
                    или вернитесь на <Link className={styles.link} to="/">главную</Link></>
                </TypographyUI.Body1Regular>
            </div>

            <div className={styles.background}>
                <StaticImage
                    className={styles.backgroundImg}
                    src="../../images/404/background.png"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={760}
                    height={685}
                    layout="fixed"
                    objectFit="contain"
                    alt="background-img"
                />
                <StaticImage
                    className={styles.backgroundGradient}
                    src="../../images/404/gradient.png"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={760}
                    height={685}
                    layout="fixed"
                    objectFit="contain"
                    alt="background-gradient"
                />
            </div>
        </div>

    </Layout>
  </>
);

export default NotFoundPage
